import { CabDropdownProps, CabDropdown } from "@CabComponents/CabDropdown";
import { InputLabel } from "@mui/material";
import { GridFilterInputMultipleValueProps } from "@mui/x-data-grid-pro";
import { AbstractEventAnalyticsLabel } from "../../store";
import { ReactElement, useEffect } from "react";
import colors from "../../colors";
import { intersection as lodashIntersection } from "lodash-es";

function FilterSelect<T extends AbstractEventAnalyticsLabel>(props: GridFilterInputMultipleValueProps) {
  const {options, rawOptions, applyValue, multiple, item} = {
    ...props
  } as GridFilterInputMultipleValueProps & CabDropdownProps<string | number> & {
    rawOptions: {[key: string]: T}
  };

  const retypedOptions = options as unknown as { 
    value: string | number;
    label: string | ReactElement;
    disabled?: boolean | undefined;
    tooltipText?: string | undefined;
  }[];

  useEffect(() => {
    if (Array.isArray(item.value)) {
      const intersection = lodashIntersection(
        Object.keys(rawOptions).map((key: string) => Number(key)),
        item.value
      );
      if (intersection.length === 0 ) {
        applyValue({...item, value: []});
      }
    } else {
      if (!rawOptions[item.value]) {
        applyValue({...item, value: []});
      }
    }
  // We need to reset selects to ensure bad state is not retains when rawOptions change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rawOptions)]);

  const optionsMap: {[key: number | string]: {value: string | number, label: string | React.ReactElement}} = {};
  retypedOptions.forEach(opt  => {
    optionsMap[opt.value] = opt;
  });

  return <>
    <InputLabel shrink={true}>Value</InputLabel>
    <CabDropdown
      multiple={multiple}
      options={options}
      value={item.value}
      onChange={(e) => applyValue({...item, value: e.target.value})}
      overrides={{
        renderValue: (value: unknown) => {
          const ids = value as number[];
          const optionObjects = ids.map(eventId => eventId != null && rawOptions[eventId]
            ? rawOptions[eventId] 
            : {id: -1, name: "None", color: colors.white800} as T);
          return <>{optionObjects.map(obj => retypedOptions.find(o => o.value === obj.id)?.label)}</>;
        },
        sx: {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
          "& .MuiSelect-multiple": {
            paddingLeft: 0.5
          },
          "& .MuiSvgIcon-root": {
            height: 16,
            width: 16
          },
          height: 32
        }
      }}
    />
  </>;
}

export default FilterSelect;
