import { GridFilterInputMultipleValueProps } from "@mui/x-data-grid-pro";
import { AbstractEventAnalyticsLabel } from "../../store";
import FilterSelect from "./FilterSelect";

function FilterMultipleSelect<T extends AbstractEventAnalyticsLabel>(props: GridFilterInputMultipleValueProps) {
  let value = props.item.value;

  if (!Array.isArray(value) ) {
    if (value) {
      value = [value];
    } else {
      value  = [];
    }
  }
  props.item.value = value;
  return <FilterSelect<T> {...props} multiple={true} />;
}

export default FilterMultipleSelect;