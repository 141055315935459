import { CabTabs } from "@CabComponents/CabTabs";
import OrganizationEventTags from "../../../components/Settings/OrganizationEventTags";


const tabs = [{
  label: 'Personal',
  id: 'personal',
}, {
  label: 'My Organization',
  id: 'org',
}];


const AnalyticsTab = () => {
  return (
    <CabTabs tabs={tabs} tabPanelSx={{ margin: 6 }}>
      <OrganizationEventTags showLabels={false} />
      <OrganizationEventTags showAutoAssign={false} />
    </CabTabs>
  );
};

export default AnalyticsTab;
