import { CabInput } from "@CabComponents/CabInput";
import { Box, FormControlLabel } from "@mui/material";
import { CirclePicker } from "react-color";
import { Control, Controller } from "react-hook-form";
import { DEFAULT_LEADER_LABEL_COLORS } from "../../../constants";

export type EventLabelAbstractFormInput = {id: number, name: string, color: string, organization?: number};

export type EventLabelAbstractModalProps = {
  control: Control<EventLabelAbstractFormInput>;
};

const EventLabelAbstractModal = ({control}: EventLabelAbstractModalProps) => {

  return <Box display="flex" flexDirection="column" gap={2}>
    <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      render={({ field }) => <FormControlLabel
        label="Name"
        labelPlacement="top"
        sx={{ textAlign: "left", width: "100%", margin: 0, alignItems: "start" }}
        control={<CabInput
          {...field}
          required
          placeholder="Name"
          sx={{ width: "100%" }}
        />}
      />}
    />
    <Controller
      name="color"
      control={control}
      render={({ field }) => <FormControlLabel
        label="Color"
        labelPlacement="top"
        sx={{ textAlign: "left", width: "100%", margin: 0, alignItems: "start", marginLeft: 1 }}
        control={<CirclePicker
          width='100%'
          circleSpacing={8}
          circleSize={32}
          colors={DEFAULT_LEADER_LABEL_COLORS}
          color={field.value}
          onChange={(color): void => field.onChange(color.hex)}
        />}
      />}
    />
  </Box>;
};

export default EventLabelAbstractModal;


