import { GridFilterInputValue, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";
import { ReactNode } from "react";
import { AbstractEventAnalyticsLabel } from "../../store";
import FilterMultipleSelect from "./MultipleFilterSelect";

export const containsOperator: GridFilterOperator[] = [
  {
    label: 'Contains',
    value: 'eq',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }

      return (value, row, colDef, apiRef): boolean => {
        return filterItem.value;
      };
    },
    InputComponent: GridFilterInputValue,
  },
];

export const selectFilterOperator: (
  props: {
    options: { value: number|string; label: ReactNode }[];
    rawOptions: Record<string, AbstractEventAnalyticsLabel>;
  }[]
) => GridFilterOperator[] = (props) => [
  {
    label: 'is any of',
    value: 'eq',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }
      return filterItem.value;
    },
    InputComponent: FilterMultipleSelect,
    InputComponentProps: props[0]
  },
];

export const numberOperator: (props: Record<string, unknown>[]) => GridFilterOperator[] = (props) => [
  {
    label: '>=',
    value: 'gte',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }
      return filterItem.value;
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: props[0]
  },
  {
    label: '>',
    value: 'gt',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }
      return filterItem.value;
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: props[0]
  },
  {
    label: '<=',
    value: 'lte',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }
      return filterItem.value;
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: props[0]
  },
  {
    label: '<',
    value: 'lt',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }
      return filterItem.value;
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: props[0]
  },
  {
    label: '=',
    value: 'eq',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator
      ) {
        return null;
      }
      return filterItem.value;
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: props[0]
  },
];