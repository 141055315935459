import { CabToggleChip } from "@CabComponents/index";
import { ReactElement } from "react";
import colors from "../../colors";

export const createSelectOptions = (
  options: {id: number, color: string, name: string}[],
  isContentEditor = false,
  disableCreate = false,
  allowNone = true
): {value: number, label: string | ReactElement}[] => {
  let noneValue: undefined | {value: number, label: ReactElement};

  if (allowNone) {
    noneValue = {
      value: -1, label: <CabToggleChip chipColor={colors.black400} selected={true} label="None"/>
    };
  }
  
  let finalOptions = options.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }).map((eventType) => ({
    value: eventType.id,
    label: <CabToggleChip chipColor={eventType.color} label={eventType.name} selected={true}/>
  }));

  if (noneValue) {
    finalOptions = [noneValue, ...finalOptions];
  }

  return [
    ...finalOptions,
    ...(isContentEditor && !disableCreate ? [{value: -2, label: "Create New"}] : [])];
};
 