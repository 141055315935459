import {
  DataGridProProps, GridCallbackDetails, GridColDef, GridFilterModel,
  GridRenderCellParams, GridSortModel, GridToolbarColumnsButton, GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import { AnalyticsEventColor, Calendar, EventCategory, EventType } from "../../../store";
import { ReactNode, useCallback, useMemo, useState} from "react";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import NoRowOverlay from "../NoRowsOverlay";
import { Box, Typography } from "@mui/material";
import colors from "../../../colors";
import { containsOperator, selectFilterOperator } from "../../DataGrid/utils";
import { createSelectOptions } from "../utils";
import { CabModal } from "@CabComponents/CabModal";
import { CabButton } from "@CabComponents/CabButton";
import CabDataGrid from "@CabComponents/CabDataGrid";

export type AutoAnalyticsEventCategoryLabelsProps = {
  analyticsColors: AnalyticsEventColor[];
  eventCategories: {[key: string]: EventCategory};
  eventTypes: {[key: string]: EventType};
  calendars: {[key: string]: Calendar};
  updateAnalyticColor: (color: AnalyticsEventColor) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails) => void;
};

const slots: DataGridProProps["slots"] = {
  noRowsOverlay: () => (
    <NoRowOverlay emptyMessage="No Color Association Data Available" msTimeout={10000} />
  ),
  toolbar:  () => <Box display="flex" flexDirection="row-reverse" sx={{ width: "100%" }}>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </Box> 
};

export const AutoAnalyticsEventCategoryLabels = (
  {
    analyticsColors, eventCategories, 
    updateAnalyticColor: finalUpdateAnalyticsColor, calendars, eventTypes,
    handleSortModelChange, handleFilterModelChange
  }: AutoAnalyticsEventCategoryLabelsProps
) => {

  const [analyticsColor, setAnalyticsColor] = useState<AnalyticsEventColor | undefined>();

  const updateAnalyticColor = useCallback((color: AnalyticsEventColor) => {
    setAnalyticsColor(color);
  }, []);

  const renderAnalyticsColor = useCallback((
    { row: { color, category, organization, calendar } }: GridRenderCellParams<AnalyticsEventColor, ReactNode>
  ) => {
    return <CabToggleChip 
      chipColor={color || undefined} label={category || undefined} 
      sx={{minWidth: 120}} 
      selected={true} 
    />;
  }, []);

  const renderAnalyticsEventCategory = useCallback((
    { row }: GridRenderCellParams<AnalyticsEventColor, ReactNode>
  ) => {
    const options = createSelectOptions(Object.values(eventCategories), false, true, true);
    return <CabDropdown
      value={row.event_category || -1}
      onChange={(e) => updateAnalyticColor(
        {...row, event_category: Number(e.target.value) > 0 ? Number(e.target.value) : null }
      )}
      options={options}
      placeholder="No Category"
      sx={{width: "100%"}}
      overrides={{
        renderValue: (value: unknown) => {
          const eventId = value as number;
          const eventCatObject = (eventId > 0 && eventCategories[eventId]) ?
            eventCategories[eventId] : { id: -1, name: "None", color: colors.white800 };
          return <CabToggleChip
            chipColor={eventCatObject.color}
            label={eventCatObject.name}
            selected={true}
          />;
        },
        sx: {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
        }
      }}
    />;
  }, [eventCategories, updateAnalyticColor]);

  const renderAnalyticsEventType = useCallback((
    { row }: GridRenderCellParams<AnalyticsEventColor, ReactNode>
  ) => {
    const options = createSelectOptions(Object.values(eventTypes), false, true, true);

    return <CabDropdown
      value={row.event_type || -1}
      onChange={(e) => updateAnalyticColor({
        ...row, event_type: Number(e.target.value) > 0 ? Number(e.target.value) : null }
      )}
      options={options}
      placeholder="No Type"
      sx={{width: "100%"}}
      overrides={{
        renderValue: (value: unknown) => {
          const eventId = value as number;
          const eventTypeObject = (eventId > 0 && eventTypes[eventId]) ?
            eventTypes[eventId] : { id: -1, name: "None", color: colors.white800 };
          return <CabToggleChip chipColor={eventTypeObject.color} label={eventTypeObject.name} selected={true} />;
        },
        sx: {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
        }
      }}
    />;
  }, [eventTypes, updateAnalyticColor]);

  const renderAnalyticsEventColorCalendar = useCallback((
    { row: { color, category, organization, calendar } }: GridRenderCellParams<AnalyticsEventColor, ReactNode>
  ) => {
    const cal = calendars[calendar];
    return cal ? <CabToggleChip 
      chipColor={cal?.backgroundColor} label={`${cal?.summary}`} 
      sx={{minWidth: 40}} 
      selected={true} 
    /> : <></>;
  }, [calendars]);

  const eventCategoryOptions = useMemo(
    () => createSelectOptions(
      Object.values(eventCategories), true, true, true),
    [eventCategories]
  );

  const eventTypeOptions = useMemo(
    () => createSelectOptions(
      Object.values(eventTypes), true, true, true),
    [eventTypes]
  );


  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'calendar',
      headerName: 'Calendar',
      width: 220,
      display: "flex",
      editable: false,
      filterable: true,
      sortable: true,
      filterOperators: containsOperator,
      renderCell: renderAnalyticsEventColorCalendar,
      headerAlign: 'left'
    },
    {
      field: 'color',
      headerName: 'Color / Label',
      width: 220,
      display: "flex",
      editable: false,
      filterable: false,
      sortable: true,
      renderCell: renderAnalyticsColor,
      headerAlign: 'left'
    },
    {
      field: 'event_category',
      headerName: 'Category',
      width: 220,
      display: "flex",
      editable: false,
      filterable: true,
      sortable: true,
      renderCell: renderAnalyticsEventCategory,
      headerAlign: 'left',
      filterOperators: selectFilterOperator([
        { options: eventCategoryOptions, rawOptions: eventCategories }
      ]).map(operator => {
        return {
          ...operator,
          getValueAsString: (value: number) => {
            if (Array.isArray(value)) {
              return value.map(v => Object.values(eventCategories).find(e => e.id === v)?.name || 'None').join(', ');
            }
            return Object.values(eventCategories).find(e => e.id === value)?.name || 'None';
          }
        };
      })
    }
    ,
    {
      field: 'event_type',
      headerName: 'Type',
      width: 220,
      display: "flex",
      editable: false,
      filterable: true,
      sortable: true,
      renderCell: renderAnalyticsEventType,
      headerAlign: 'left',
      filterOperators: selectFilterOperator([
        { options: eventTypeOptions, rawOptions: eventTypes }
      ]).map(operator => {
        return {
          ...operator,
          getValueAsString: (value: number) => {
            if (Array.isArray(value)) {
              return value.map(v => Object.values(eventTypes).find(e => e.id === v)?.name || 'None').join(', ');
            }
            return Object.values(eventTypes).find(e => e.id === value)?.name || 'None';
          }
        };
      })
    }
  ], [eventCategories, eventCategoryOptions, eventTypeOptions, eventTypes, renderAnalyticsColor, 
    renderAnalyticsEventCategory, renderAnalyticsEventColorCalendar, renderAnalyticsEventType]);

  const applyAnalyticsColorEventChange = () => {
    if (analyticsColor) {
      finalUpdateAnalyticsColor(analyticsColor);
    }
    setAnalyticsColor(undefined);
  };

  const dataRows = useMemo(() => ( 
    Object.keys(calendars).length > 0 ? analyticsColors : [] 
  ), [analyticsColors, calendars]);

  return <><CabDataGrid
    rows={dataRows}
    columns={columns}
    paginationMode="client"
    filterMode="server"
    sortingMode="server"
    hideFooter={true}
    autoHeight
    disableVirtualization
    autoPageSize
    onSortModelChange={handleSortModelChange}
    onFilterModelChange={handleFilterModelChange}
    slots={slots}
  />
  <CabModal open={!!analyticsColor} actionButtons={<>
    <CabButton buttonType='tertiary' onClick={() => setAnalyticsColor(undefined)}>Cancel</CabButton>
    <CabButton onClick={applyAnalyticsColorEventChange}
    >
      Apply
    </CabButton>
  </>}>
    <Box>
      <Typography>
        This will apply to all existing events, and future events.
      </Typography>
    </Box>
  </CabModal>
  </>;
};

export default AutoAnalyticsEventCategoryLabels;